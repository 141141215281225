export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const orbitApiFetchInstance = createOrbitApiFetchInstance(config.public)
  return {
    provide: {
      // provide orbit resources
      avatars: apiFetchRepository(orbitApiFetchInstance, 'v2/avatars'),
      employees: apiFetchRepository(orbitApiFetchInstance, 'v2/employees'),
      users: apiFetchRepository(orbitApiFetchInstance, 'v2/users')
    }
  }
})
