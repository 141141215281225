import { default as indexPD22gyGw2hMeta } from "/vercel/path0/apps/tutor/src/pages/index.vue?macro=true";
import { default as loginwbnIDyLA4VMeta } from "/vercel/path0/apps/tutor/src/pages/login.vue?macro=true";
import { default as indexGfAtLHAHOdMeta } from "/vercel/path0/apps/tutor/src/pages/profile/index.vue?macro=true";
import { default as scheduleW2Z0Bo8KsiMeta } from "/vercel/path0/apps/tutor/src/pages/schedule.vue?macro=true";
import { default as secretBrbSsNwc1xMeta } from "/vercel/path0/apps/tutor/src/pages/secret.vue?macro=true";
import { default as indexU8kNdj2fO1Meta } from "/vercel/path0/apps/tutor/src/pages/students/[studentId]/index.vue?macro=true";
import { default as profilex4xmV8s3YMMeta } from "/vercel/path0/apps/tutor/src/pages/students/[studentId]/profile.vue?macro=true";
import { default as index4pv304YldYMeta } from "/vercel/path0/apps/tutor/src/pages/students/index.vue?macro=true";
export default [
  {
    name: indexPD22gyGw2hMeta?.name ?? "index",
    path: indexPD22gyGw2hMeta?.path ?? "/",
    meta: indexPD22gyGw2hMeta || {},
    alias: indexPD22gyGw2hMeta?.alias || [],
    redirect: indexPD22gyGw2hMeta?.redirect,
    component: () => import("/vercel/path0/apps/tutor/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginwbnIDyLA4VMeta?.name ?? "login",
    path: loginwbnIDyLA4VMeta?.path ?? "/login",
    meta: loginwbnIDyLA4VMeta || {},
    alias: loginwbnIDyLA4VMeta?.alias || [],
    redirect: loginwbnIDyLA4VMeta?.redirect,
    component: () => import("/vercel/path0/apps/tutor/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexGfAtLHAHOdMeta?.name ?? "profile",
    path: indexGfAtLHAHOdMeta?.path ?? "/profile",
    meta: indexGfAtLHAHOdMeta || {},
    alias: indexGfAtLHAHOdMeta?.alias || [],
    redirect: indexGfAtLHAHOdMeta?.redirect,
    component: () => import("/vercel/path0/apps/tutor/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: scheduleW2Z0Bo8KsiMeta?.name ?? "schedule",
    path: scheduleW2Z0Bo8KsiMeta?.path ?? "/schedule",
    meta: scheduleW2Z0Bo8KsiMeta || {},
    alias: scheduleW2Z0Bo8KsiMeta?.alias || [],
    redirect: scheduleW2Z0Bo8KsiMeta?.redirect,
    component: () => import("/vercel/path0/apps/tutor/src/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: secretBrbSsNwc1xMeta?.name ?? "secret",
    path: secretBrbSsNwc1xMeta?.path ?? "/secret",
    meta: secretBrbSsNwc1xMeta || {},
    alias: secretBrbSsNwc1xMeta?.alias || [],
    redirect: secretBrbSsNwc1xMeta?.redirect,
    component: () => import("/vercel/path0/apps/tutor/src/pages/secret.vue").then(m => m.default || m)
  },
  {
    name: indexU8kNdj2fO1Meta?.name ?? "students-studentId",
    path: indexU8kNdj2fO1Meta?.path ?? "/students/:studentId()",
    meta: indexU8kNdj2fO1Meta || {},
    alias: indexU8kNdj2fO1Meta?.alias || [],
    redirect: indexU8kNdj2fO1Meta?.redirect,
    component: () => import("/vercel/path0/apps/tutor/src/pages/students/[studentId]/index.vue").then(m => m.default || m)
  },
  {
    name: profilex4xmV8s3YMMeta?.name ?? "students-studentId-profile",
    path: profilex4xmV8s3YMMeta?.path ?? "/students/:studentId()/profile",
    meta: profilex4xmV8s3YMMeta || {},
    alias: profilex4xmV8s3YMMeta?.alias || [],
    redirect: profilex4xmV8s3YMMeta?.redirect,
    component: () => import("/vercel/path0/apps/tutor/src/pages/students/[studentId]/profile.vue").then(m => m.default || m)
  },
  {
    name: index4pv304YldYMeta?.name ?? "students",
    path: index4pv304YldYMeta?.path ?? "/students",
    meta: index4pv304YldYMeta || {},
    alias: index4pv304YldYMeta?.alias || [],
    redirect: index4pv304YldYMeta?.redirect,
    component: () => import("/vercel/path0/apps/tutor/src/pages/students/index.vue").then(m => m.default || m)
  }
]